import { template as template_bfc1c83118cc40df9bd09baf1fe8bd21 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_bfc1c83118cc40df9bd09baf1fe8bd21(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
