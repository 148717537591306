import { template as template_1d7659e6cb6e47159908709eb1d980e8 } from "@ember/template-compiler";
const FKLabel = template_1d7659e6cb6e47159908709eb1d980e8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
