import { template as template_60a557d368cf4aa0b96008fa9c9ac3fe } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_60a557d368cf4aa0b96008fa9c9ac3fe(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
