import { template as template_47f823d9fca246e6a0f0cd77f9b7486e } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_47f823d9fca246e6a0f0cd77f9b7486e(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
